import styled from "styled-components";
export const FeedWrap = styled.div`
  display: block;
  @media (min-width: 1200px) {
    display: flex;
    gap: 10px;
    flex: 1;
  }
 
`;
